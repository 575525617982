import "./App.css";
import { Routes, Route, Navigate } from "react-router-dom";

import Home from "./Components/Home/Home";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import YolYardimi from "./Components/YolYardimi/YolYardimi";
import IlkArac from "./Components/ILkArac/ILkArac";
import Iletism from "./Components/Pages/ILETISIM";
import RiskHayatSigortasi from "./Components/Pages/RiskHayatSigortasi";
import MeslekiYetersizlikSigortası from "./Components/Pages/MeslekiYetersizlikSigortası";

function App() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/yol-yardimi" element={<YolYardimi />} />
        <Route path="/ilk-arac" element={<IlkArac />} />
        <Route path="/iletisim" element={<Iletism />} />
        <Route path="/risk-hayat-sigortasi" element={<RiskHayatSigortasi />} />
        <Route path="/mesleki-yetersizlik-sigortası" element={<MeslekiYetersizlikSigortası />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
