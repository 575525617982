export const links = [
  {
    name: "SIGORTALAR",
    submenu: true,
    sublinks: [
      {
        Head: "Araç Sigortası",
        sublink: [
          { name: "İlk Araç", link: "/ilk-arac" },
          { name: "Araç Vergi Hesaplayıcı", link: "/" },
          { name: "Hasarsızlık Sınıfları", link: "/" },
          { name: "Kısmi ve Kapsamlı Sigorta", link: "/" },
          { name: "Araç Sigortasını değiştir", link: "/" },
          { name: "Araç Sigortasını iptal et", link: "/" },
          { name: "Araç Kayıt Büroları", link: "/" },
        ],
      },
      {
        Head: "Risk Hayat Sigortası",
        link: "/risk-hayat-sigortasi",
        sublink: [],
      },
      {
        Head: "Mesleki Yetersizlik Sigortası",
        link: "/mesleki-yetersizlik-sigortası",
        sublink: [],
      },
      {
        Head: "Özel Sorumluluk Sigortası",
        sublink: [],
      },
      {
        Head: "Ev Eşyası Sigortası",
        sublink: [],
      },
      {
        Head: "Kaza Sigortası",
        sublink: [],
      },
      {
        Head: "Konut Sigortası",
        sublink: [],
      },
      {
        Head: "Hukuki Koruma Sigortası",
        sublink: [],
      },
      {
        Head: "Ek Sağlık Sigortası",
        sublink: [],
      },
      {
        Head: "Sağlık Sigortası",
        sublink: [
          { name: "Yasal Sağlık Sigortası", link: "/" },
          { name: "Özel Sağlık Sigortası", link: "/" },
        ],
      },
      {
        Head: "Defin Masrafları Sigortası",
        sublink: [],
      },
      {
        Head: "Köpek Mesuliyet Sigortası",
        sublink: [],
      },
    ],
  },
];
