import React, { useEffect, useState, useRef } from "react";

import slideImg1 from "../../Assets/slideImg1.png";
import slideImg2 from "../../Assets/slideImg2.png";
import slideImg3 from "../../Assets/slideImg3.png";
import slideImg4 from "../../Assets/slideImg4.png";

import "./Home.css";

// import hairdye from "../../Assets/beauty-treatment.png";
// import haircut from "../../Assets/haircut.png";
// import makeup from "../../Assets/makeover.png";
// import hairtrim from "../../Assets/beard-trimming.png";

// import { ServiceSection } from "../Sections/ServiceCard/ServiceCard";
// import { FAQs } from "../Sections/FAQ/FAQ";
// import { Article } from "../Sections/Articles/Articles";
// import { ReviewSlider } from "../Sections/Review/Review";

// import { TeamBanner } from "../Sections/TeamBanner/TeamBanner";
// import { TakeCare } from "../Sections/TakeCare/TakeCare";
// import { SearchBanner } from "../Sections/SearchBanner/SearchBanner";
// import { HelmetSEO } from "../../HelmetSEO";
// import { BookAppointment, ReadMore } from "../Sections/Button/Button";
// import CounterSteps from "../Sections/CounterSteps/CounterSteps";
// import TimingsSection from "../Sections/TimingsSection/TimingsSection";

const faqsData = [
  {
    question: "Neden Sigortanız.de?",
    answer:
      "Sigortaniz.de kendinizi herhangi bir riske karşı sigorta etmenizi kolaylaştırır",
  },
  {
    question: "Araç sigortası",
    answer:
      "Kasko sigortası mali mesuliyet sorumluluk sigortasını tamamlar ve aracınız hasar aldığında öder.",
  },
  {
    question: "Özel sorumluluk sigortası",
    answer:
      "Bazen birkaç saniye dikkatsizlik tatsız bir duruma sebep olabilir.",
  },
  {
    question: "Hukuki Koruma Sigortası",
    answer:
      "Meslekte, trafikte ya da komşularınızda: İnsanların karşılaştığı her yerde ansızın fikir ayrılığı yaşanabilir ya da kavga meydana gelebilir.",
  },
  {
    question: "Ev Eşyası Sigortası",
    answer:
      "Herkesin malı mülkü onun için kıymetlidir. Bu isterse DVD koleksiyonu olsun ya da yeni alınan çamaşır makinesi ya da yeni, büyük bir LED televizyon.",
  },
];

export const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // If clicked again, close it
    } else {
      setOpenIndex(index); // Else, open the clicked one
    }
  };

  return (
    <div className="container mx-auto">
      <div className="py-12 px-4">
        <div className="grid md:grid-cols-2 gap-8 items-center">
          <div>
            <img
              src="https://blobs.digitalcdn.co.za/cdn-cgi/image/fit=scale-down,format=auto,metadata=keep,sharpen=0.5/blobs/a1dced3085c94111bb19e48edafcdf13/93180bd1356f4eb08d290004040556f5.jpg"
              alt=""
              className="rounded-md w-full h-full max-h-[500px]"
            />
          </div>
          <div class="aspect-w-16 aspect-h-9">
            <div className="max-w-2xl mx-auto">
              {faqsData.map((faq, index) => (
                <div key={index} className="mb-4">
                  <button
                    onClick={() => toggleFAQ(index)}
                    className="flex justify-between items-center w-full px-4 py-4 text-left text-white bg-darkgreen rounded-t-md hover:opacity-90 "
                  >
                    <span>{faq.question}</span>
                    <span>{openIndex === index ? "▲" : "▼"}</span>
                  </button>
                  {openIndex === index && (
                    <div className="px-4 pt-4 pb-2 text-md bg-white text-black rounded-b-md">
                      {faq.answer}
                    </div>
                  )}
                </div>
              ))}
              <p className="py-4 text-lg font-bold">
                Neye ihtiyacınız olduğundan emin değil misiniz? Bize ulaşın,
                size yardımcı olacağız
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const slides = [
  {
    id: 1,
    imageUrl: slideImg1,
    headline: "Trafikte güvenli",
    text: "Araç sİgortası: Trafİk rİsklerİne karşı güvenlİ ve emnİyetlİ",
    buttonText: "TEKLİF AL",
  },
  {
    id: 2,
    imageUrl: slideImg2,
    headline: "Hayalini yaşa",
    text: "Hayat sİgortası: Ömür boyu korumadan daha fazlası",
    buttonText: "TEKLİF AL",
  },
  {
    id: 3,
    imageUrl: slideImg3,
    headline: "Yasal Koruma",
    text: "Hukuki Koruma Sigortası: yasal bir anlaşmazlıkta maliyet riskine karşı",
    buttonText: "TEKLİF AL",
  },
  {
    id: 4,
    imageUrl: slideImg4,
    headline: "Özel SOrumluluk",
    text: "Özel Sorumluluk Sigortası: Herkesin özel sorumluluk sigortası olmalıdır",
    buttonText: "TEKLİF AL",
  },
];

function BackgroundImageSlider() {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveSlide((currentSlide) => (currentSlide + 1) % slides.length);
    }, 8000); // Change slide every 8 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="relative w-full h-screen max-h-[580px] ">
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`absolute inset-0 transition-opacity duration-1000 ease-in-out ${
            index === activeSlide ? "opacity-100" : "opacity-0"
          }`}
          style={{
            backgroundImage: `url(${slide.imageUrl})`,
            backgroundSize: "cover",
          }}
        >
          <div className="flex h-full items-center justify-center bg-black bg-opacity-30">
            <div className="text-white space-y-8 max-w-lg px-4">
              <span className="text-lg">{slide.headline}</span>
              <h2 className="text-4xl font-extrabold">{slide.text}</h2>
              <button className="mt-4 px-4 py-2.5 bg-white text-darkgreen font-bold rounded transition text-lg">
                {slide.buttonText}
              </button>
            </div>
          </div>
        </div>
      ))}
      <div className="absolute bottom-5 w-full flex justify-center">
        {slides.map((_, index) => (
          <span
            key={index}
            className={`inline-block h-3 w-3 mx-2 rounded-full ${
              index === activeSlide ? "bg-white" : "bg-gray-400"
            } cursor-pointer`}
            onClick={() => setActiveSlide(index)}
          ></span>
        ))}
      </div>
    </div>
  );
}

const services = [
  {
    title: "İç Huzuru",
    image: "fa-solid fa-peace text-4xl text-darkgreen",
    description:
      "Sigorta Şirketi koruma faaliyetlerini sürdürmekte, hafifletmektedir. Yaşam için ayarla Müşteriler bize en çok sigorta sevmedikleri şeyleri anlattılar. Bu yararlı web sitesini oluşturduk.",
  },
  {
    title: "Özel Çözümle",
    image: "fa-solid fa-headset text-4xl text-darkgreen",
    description:
      "Almanya’da sigorta konusunda profesyonel danışmanlık hizmeti veriyoruz.",
  },
  {
    title: "İç Dinginlik",
    image: "fa-solid fa-shield-halved text-4xl text-darkgreen",
    description:
      "Risklerle dolu bir dünyada, güvenli bir liman olmayı amaçlıyoruz. Müşterilerimizin ihtiyaçlarını dinleyerek, onlara özelleştirilmiş sigorta çözümleri sunuyoruz.",
  },
];

const ServiceCard = ({ title, image, description }) => {
  return (
    <div
      className="rounded-md w-full bg-white p-4 flex flex-col items-center border border-darkgreen"
      style={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <i class={image}></i>
      <h1 className="text-center text-2xl font-bold py-2">{title}</h1>
      <p className="text-gray-500 text-center flex-grow">{description}</p>
    </div>
  );
};

const ServiceSection = () => {
  return (
    <div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-2 lg:gap-4">
        {services.map((service, index) => (
          <div key={index} className="px-2 py-4 lg:p-4 h-full">
            <ServiceCard {...service} />
          </div>
        ))}
      </div>
    </div>
  );
};

function Home() {
  // const [lightbox, setLightbox] = useState({ open: false, imgSrc: "" });
  // const openLightbox = (imgSrc) => {
  //   setLightbox({ open: true, imgSrc });
  // };

  // const closeLightbox = () => {
  //   setLightbox({ open: false, imgSrc: "" });
  // };
  return (
    <>
      {/* <HelmetSEO
        title="Friseur - Bester Entspannungs- und Friseursalon"
        description="Entdecken Sie Friseur Katharinas in Dortmund Benninghofen, einen erstklassigen Friseursalon, der für individuelle Styling-Services für Männer, Frauen und Kinder bekannt ist. Genießen Sie professionelle Haarschnitte, Färbungen und eine warme Atmosphäre mit modernem Dekor. Für Ihren besten Look verwenden wir hochwertige Produkte und bleiben über die neuesten Trends auf dem Laufenden."
        url="https://www.friseur-katharinas.de/"
      /> */}

      <div class="bg-white">
        <BackgroundImageSlider />
        <section>
          <div className="container mx-auto px-4 py-16">
            <h2 className="font-extrabold text-xl md:text-2xl [text-wrap:balance] bg-clip-text text-transparent bg-gradient-to-r from-slate-500/60 to-50% to-slate-500 text-center">
              Neye ihtiyacınız olduğundan emin değil misiniz? Bize ulaşın, size
              yardımcı olacağız
            </h2>
          </div>
        </section>
        <section className="bg-lightgray">
          <FAQs />
        </section>
        <section>
          <div className="container mx-auto px-4 py-12">
            <h2 className="text-4xl font-extrabold text-center pb-4 text-transparent bg-clip-text bg-gradient-to-r to-darkgreen from-mediumgreen">
              Neden bizi seçmelisiniz?
            </h2>
            <ServiceSection />
          </div>
        </section>

        {/* <div className="text-black relative isolate overflow-hidden bg-lightgray py-16 sm:py-24 lg:py-32">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:max-w-none lg:grid-cols-2">
              <div className="max-w-xl lg:max-w-lg">
                <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">
                  Haber bültenimize abone ol.
                </h2>
                <p className="mt-4 text-lg leading-8 text-gray-500">
                  Güncel kalın. Sigortacılık sektöründeki haberleri blogumuzda
                  paylaşıyoruz
                </p>
                <div className="mt-6 flex max-w-md gap-x-4">
                  <label htmlFor="email-address" className="sr-only">
                    Email address
                  </label>
                  <input
                    id="email-address"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="min-w-0 flex-auto rounded-md border border-gray-400 bg-white/5 px-3.5 py-2 text-black shadow-sm sm:text-sm sm:leading-6 outline-none focus:outline-none"
                    placeholder="Enter your email"
                  />
                  <button
                    type="submit"
                    className="flex-none rounded-md bg-darkgreen px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm focus-visible:outline outline-none"
                  >
                    Subscribe
                  </button>
                </div>
              </div>
              <dl className="grid grid-cols-1 gap-x-8 gap-y-10 sm:grid-cols-2 lg:pt-2">
                <div className="flex flex-col items-start">
                  <div className="rounded-md bg-white/5 py-2 px-3 ring-1 ring-gray-400">
                    <i class="fa-solid fa-calendar-days text-xl text-gray-500"></i>
                  </div>
                  <dt className="mt-4 font-semibold ">Haftalık Makaleler</dt>
                  <dd className="mt-2 leading-7 text-gray-500">
                  Her hafta, sizin için seçtiğimiz konuları derinlemesine ele alıyor, alanında uzman kişilerin görüş ve yorumlarını sizlerle paylaşıyoruz. Bilgi dolu içeriklerimizle, merak ettiğiniz konulara ışık tutuyoruz.
                  </dd>
                </div>
                <div className="flex flex-col items-start">
                  <div className="rounded-md bg-white/5 py-2 px-3 ring-1 ring-gray-400">
                    <i class="fa-regular fa-hand text-xl text-gray-500"></i>
                  </div>
                  <dt className="mt-4 font-semibold ">Spamsız İçerik</dt>
                  <dd className="mt-2 leading-7 text-gray-500">
                  Gönderilerimizde kaliteyi ön planda tutuyor, sadece değerli bulduğumuz, sizin için önem taşıdığını düşündüğümüz içerikleri paylaşıyoruz. İstenmeyen e-postalardan uzak, sadece merak ettiğiniz, bilgi veren içerikler burada.
                  </dd>
                </div>
              </dl>
            </div>
          </div>
          <div
            className="absolute left-1/2 top-0 -z-10 -translate-x-1/2 blur-3xl xl:-top-6"
            aria-hidden="true"
          >
            <div
              className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30"
              style={{
                clipPath:
                  "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
              }}
            />
          </div>
        </div> */}
      </div>
    </>
  );
}

export default Home;
