import whatappLogo from "../../Assets/whatsapp.png";
import DEVKlogo from "../../Assets/DEVKlogo.png";
export default function Button(){
    return(
        <div className="mx-auto bg-lightgray w-full max-w-[280px] rounded-full flex flex-col items-center gap-2 p-4 hover:bg-gray-200 cursor-pointer">
        <img src={whatappLogo} className="self-end mr-8 w-8 h-8"></img>

        <h2 className="text-lg text-center text-darkgreen font-bold">
          Teklif için buraya tıklayın
        </h2>
        <div className="flex gap-2">
          <img src={DEVKlogo} className="w-full h-12"></img>
          <div className="flex flex-col border-gray-600 pl-2 border-l text-darkgreen font-bold">
            <h3 className="text-xs">Gesagt.</h3>
            <h3 className="text-xs">Getan.</h3>
            <h3 className="text-xs">Geholfen.</h3>
          </div>
        </div>
      </div>
    )
}