import { useState, useEffect } from "react";
function Footer() {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    // Update the year every time the component mounts
    setCurrentYear(new Date().getFullYear());
  }, []); // Empty dependency array ensures it runs only once on mount

  return (
    <footer
      className=" text-white px-4 py-8 md:p-10 bg-darkgreen"
      // style={{
      //   backgroundImage:
      //     "linear-gradient(90deg, #005437, #7ed957, #8bc379, #d1e6c9)",
      // }}
    >
      <div className="max-w-6xl mx-auto grid grid-cols-1 md:grid-cols-4 gap-8 text-center md:text-left">
        <div>
          <h2 className="text-lg font-bold mb-4 uppercase">Sigortaniz.de</h2>
          <p>
            Geleceğe hazırlanmanıza yardımcı olabilecek kaynaklar ve araçlar
            alın.
          </p>
          {/* <div className="flex flex-col items-center sm:items-start">
            <img src={footerLogo} className="w-full h-full py-4"></img>
          </div> */}
        </div>

        <div>
          <h1 className="font-bold text-lg mb-4">BİZE ULAŞIN</h1>
          <p className="mb-4">
            <i className="fas fa-phone-alt mr-2"></i>
            <a href="tel:0221212223" className="text-xs sm:text-base">
              0221 21 22 23
            </a>
          </p>
          <p className="mb-4">
            <i class="fa-solid fa-mobile-screen-button mr-2"></i>
            <a href="tel:+4917656901923" className="text-xs sm:text-base">
              0176 56 90 19 23
            </a>
          </p>
          <p className="mb-4">
            <i className="fas fa-envelope mr-2"></i>
            <a href="mailto:info@sigortaniz.de" className="">
              info@sigortaniz.de
            </a>
          </p>

          <p className="mb-4">
            <i class="fa-regular fa-clock mr-2"></i>PZT-CUM: 9:00-18:00
          </p>
        </div>

        <div>
          <h2 className="font-bold text-lg mb-4">SIGORTALAR</h2>
          <ul>
            <li className="mb-2">
              <a className="hover:underline" href="/services">
                Araç Sigortası
              </a>
            </li>
            <li className="mb-2">
              <a className="hover:underline" href="/about-us">
                Özel Sorumluluk Sigortası
              </a>
            </li>
            <li className="mb-2">
              <a className="hover:underline" href="/events">
                Ev Eşyası Sigortası
              </a>
            </li>
            {/* <li className="mb-2">
              <a className="hover:underline" href="/gallery">
                Kaza Sigortası
              </a>
            </li> */}
            <li className="mb-2">
              <a className="hover:underline" href="/contact-us">
                Konut Sigortası
              </a>
            </li>
            <li className="mb-2">
              <a className="hover:underline" href="/imprint">
                Hukuki Koruma Sigortası
              </a>
            </li>
            {/* <li className="mb-2">
              <a className="hover:underline" href="/imprint">
                Ek Sağlık Sigortası
              </a>
            </li> */}
            <li className="mb-2">
              <a className="hover:underline" href="/imprint">
                Sağlık Sigortası
              </a>
            </li>
            {/* <li className="mb-2">
              <a className="hover:underline" href="/imprint">
                Defin Masrafları Sigortası
              </a>
            </li> */}
            {/* <li className="mb-2">
              <a className="hover:underline" href="/imprint">
                Köpek Mesuliyet Sigortası
              </a>
            </li> */}
          </ul>
        </div>

        <div>
          <h2 className="font-bold text-lg mb-4">KURUMSAL BİLGİ</h2>
          <ul>
            <li className="mb-2">
              <a className="hover:underline" href="/services">
                Ana Sayfa
              </a>
            </li>
            <li className="mb-2">
              <a className="hover:underline" href="/about-us">
                Yol Yardimi
              </a>
            </li>
            <li className="mb-2">
              <a className="hover:underline" href="/events">
                İletişim
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div className="border-t border-white mt-8 pt-8">
        <p className="text-center text-sm">
          &copy; Copyright {currentYear}. Tüm hakları saklıdır
        </p>
      </div>
    </footer>
  );
}
export default Footer;
