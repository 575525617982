import React, { useState } from "react";
import { Link } from "react-router-dom";
import { links } from "./Mylinks";

const NavLinks = () => {
  const [heading, setHeading] = useState("");
  const [subHeading, setSubHeading] = useState("");
  return (
    <>
      {links.map((link) => (
        <div>
          <div className="px-3 text-left md:cursor-pointer group">
            <li
              className="py-6 flex justify-between items-center md:pr-0 pr-5 group hover:text-darkgreen"
              onClick={() => {
                heading !== link.name ? setHeading(link.name) : setHeading("");
                setSubHeading("");
              }}
            >
              {link.name}
              <span className="text-md md:hidden inline">
                <ion-icon
                  name={`${
                    heading === link.name ? "chevron-up" : "chevron-down"
                  }`}
                ></ion-icon>
              </span>
              <span className="text-md text-gray-700 md:mt-1 md:ml-2  md:block hidden group-hover:rotate-180 group-hover:-mt-2">
                <ion-icon name="chevron-down"></ion-icon>
              </span>
            </li>
            {link.submenu && (
              <div>
                <div className="z-50 absolute top-18 right-16 hidden group-hover:md:block hover:md:block shadow-md">
                  <div className="py-3">
                    <div
                      className="w-4 h-4 left-3 absolute 
                    mt-1 bg-white rotate-45"
                    ></div>
                  </div>
                  <div className="bg-white p-4 grid grid-cols-3 gap-4">
                    {link.sublinks.map((mysublinks) => (
                      <div>
                        <li
                          onClick={() =>
                            subHeading !== mysublinks.Head
                              ? setSubHeading(mysublinks.Head)
                              : setSubHeading("")
                          }
                          className="text-md text-gray-700 hover:text-darkgreen hover:underline"
                        >
                          {mysublinks.link ? (
                            <Link to={mysublinks.link}>{mysublinks.Head}</Link>
                          ) : (
                            mysublinks.Head
                          )}
                          <span className="text-sm text-gray-700 md:mt-2 md:ml-2 inline">
                            {mysublinks.sublink.length > 0 && (
                              <ion-icon
                                name={
                                  subHeading === mysublinks.Head
                                    ? "chevron-up"
                                    : "chevron-down"
                                }
                              ></ion-icon>
                            )}
                          </span>
                        </li>
                        {mysublinks.sublink.length > 0 && (
                          <div
                            className={`py-2 pl-2 space-y-4 ${
                              subHeading === mysublinks.Head
                                ? "md:block"
                                : "hidden"
                            }`}
                          >
                            {mysublinks.sublink.map((slink) => (
                              <li className="text-sm text-gray-600 my-2 hover:text-darkgreen hover:underline">
                                <Link
                                  to={slink.link}
                                  className="hover:text-primary uppercase"
                                >
                                  {slink.name}
                                </Link>
                              </li>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* Mobile menus */}
          <div
            className={`
            ${heading === link.name ? "md:hidden" : "hidden"}
          `}
          >
            {/* sublinks */}
            {link.sublinks.map((slinks) => (
              <div>
                <div>
                  <h1
                    onClick={() =>
                      subHeading !== slinks.Head
                        ? setSubHeading(slinks.Head)
                        : setSubHeading("")
                    }
                    className="py-4 pl-4 md:pr-0 pr-5 flex justify-between items-center uppercase"
                  >
                    {slinks.Head}

                    <span className="text-md md:mt-1 md:ml-2 inline">
                      {slinks.sublink.length > 0 && (
                        <ion-icon
                          name={
                            subHeading === slinks.Head
                              ? "chevron-up"
                              : "chevron-down"
                          }
                        ></ion-icon>
                      )}
                      {/* <ion-icon
                        name={`${
                          subHeading === slinks.Head
                            ? "chevron-up"
                            : "chevron-down"
                        }`}
                      ></ion-icon> */}
                    </span>
                  </h1>
                  <div
                    className={`${
                      subHeading === slinks.Head ? "md:hidden" : "hidden"
                    }`}
                  >
                    {slinks.sublink.map((slink) => (
                      <li className="py-3 pl-6 uppercase">
                        <Link to={slink.link}>{slink.name}</Link>
                      </li>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default NavLinks;
