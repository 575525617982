import React, { useEffect, useState, useRef } from "react";

import ACV_Logo from "../../Assets/ACV_Logo_Claim_Unten_RGB_orange_anthrazit.png";
import DEVKlogo from "../../Assets/DEVKlogo.png";
import financeImg from "../../Assets/workFinance.png";
import dangerLogo from "../../Assets/Design ohne Titel (14).png";

import ilkAracImg1 from "../../Assets/ilk-arac-img1.png";
import ilkAracImg2 from "../../Assets/ilk-arac-img2.png";
import ilkAracImg3 from "../../Assets/ilk-arac-img3.png";
import helpIcon from "../../Assets/help-icon.png";

import icon1 from "../../Assets/icon1.png";
import icon2 from "../../Assets/icon2.png";
import icon3 from "../../Assets/icon3.png";
import icon4 from "../../Assets/icon4.png";
import Button from "../Button/Button";

const statsData = [
  {
    stat: "34,5%",
    text: "Sinir Hastalıkları ve Psikolojik Rahatsızlıklar",
  },
  {
    stat: "20,1%",
    text: "Iskelet ve Hareket Sistemi Hastalıkları",
  },
  {
    stat: "17,4%",
    text: "Kanser ve Benzeri Hastalıklar",
  },
  {
    stat: "7,6%",
    text: "Kazalar",
  },
  {
    stat: "7,0%",
    text: "Kalp ve Damar Hastalıkları",
  },
  {
    stat: "13,5%",
    text: "Diğer Hastalıklar",
  },
];

function MeslekiYetersizlikSigortası() {
  const [lightbox, setLightbox] = useState({ open: false, imgSrc: "" });
  const openLightbox = (imgSrc) => {
    setLightbox({ open: true, imgSrc });
  };

  const closeLightbox = () => {
    setLightbox({ open: false, imgSrc: "" });
  };

  return (
    <>
      <div class="bg-white">
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <div className="space-y-4">
                  <h2 className="text-2xl font-bold text-darkgreen">
                    Mesleki Yetersizlik Sigortası
                  </h2>
                  <p>
                    İş gücünüz, mesleğinizdeki sermayenizdir. Ancak genç
                    yaşlarda kendiliğinden olan bu durum, meslek hayatı boyunca
                    hızla değişebilir. Bir kaza, alerjiler veya ciddi bir
                    hastalık: Pek çok sebep sizi iş hayatından koparabilir ve
                    geliriniz düşebilir. Böyle bir durumda devletin sağladığı
                    yardımlar azdır. 1 Ocak 1961'den sonra doğanlar için artık
                    devlet tarafından sağlanan bir meslek edinemezlik koruması
                    yoktur. Bu yüzden, meslek edinemezliği durumunda yeterli bir
                    gelire sahip olabilmek için doğru özel sigorta koruması
                    hayati önem taşır.
                  </p>
                </div>
              </div>
              <div class="aspect-w-16 aspect-h-9 mx-auto">
                <img
                  src={ilkAracImg1}
                  alt=""
                  className="w-full h-full max-h-[400px] cursor-pointer"
                  onClick={() => openLightbox(ilkAracImg1)}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="aspect-w-16 aspect-h-9 mx-auto order-2 md:order-1">
                <img
                  src={financeImg}
                  alt=""
                  className="w-full h-full max-h-[400px] cursor-pointer"
                  onClick={() => openLightbox(financeImg)}
                />
              </div>
              <div className="order-1 md:order-2">
                <div className="space-y-4">
                  <p>
                    Emeklilik henüz uzakken, iş gücünün kaybı finanslarınız
                    üzerinde ciddi etkilere sahip olabilir. Ancak bu böyle olmak
                    zorunda değil. Erken dönemde yapılan bir meslek edinemezlik
                    sigortası koruma sağlar. Bu, iş gücünüz için bir tür kasko
                    gibidir ve kaybedildiğinde devreye girer. Ayrıca, bir aracın
                    kasko korumasından çok daha önemlidir: Meslek edinemezlik
                    durumunda söz konusu olan miktarlar, bir aracın hasarıyla
                    kıyaslanmayacak kadar büyüktür. Üstelik, hasar görmüş bir
                    aracın aksine, iş gücü o kadar kolay yerine konulamaz.
                  </p>
                  <p>
                    Meslek edinemezlik durumunda, sigorta şirketi, korunan son
                    yaşa kadar sürekli olarak ödenen aylık bir meslek
                    edinemezlik maaşı şeklinde ödeme yapar. Bu, sigortalının
                    alışık olduğu seviyede bir yaşam sürdürmesini sağlar.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 py-16">
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-darkgreen">
                Mesleki Yetersizlik Sigortası Hizmetleri
              </h2>
              <p>
                Meslek edinemezlik sigortasının temel unsuru, meslek edinemezlik
                durumu var olduğunda ya da bu durumun doktor raporları ile
                belgelendirilip sigorta şirketi tarafından incelendikten sonra,
                garanti altına alınan bir meslek edinemezlik maaşının
                ödenmesidir. Memurlar da dahil olmak üzere, çalışanlar ve
                serbest meslek sahipleri, devlet güvencesinin eksikliklerini
                kapatabilmek adına, olası bir meslek edinemezliğe karşı bir
                hizmet edinemezlik sigortası yapmalıdır.
              </p>
              <p>
                Meslek edinemezlik sigortası, sigortalının sözleşmede belirlenen
                engellilik derecesine sahip olduğunu ve meslek edinemezliğin
                sürekli olacağının öngörüldüğü durumlarda, anlaşmalı meslek
                edinemezlik sigortasının maaşını öder. Sigorta kapsamının önemli
                detaylarından biri, meslek edinemezliği için hangi yaşa kadar
                koruma istediğinizdir.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="flex-col space-y-4">
                <h2 className="text-2xl font-bold text-darkgreen">
                  Meslek Edinemezliğinin Nedenleri
                </h2>
                <p>
                  Ortalama olarak, her dört çalışandan biri, çalışma hayatı
                  boyunca en az bir kez uzun süreli olarak meslek edinemezlik
                  durumuna düşmektedir. Sıklıkla, sinirsel ve psikolojik
                  rahatsızlıklar bir kişinin mesleğini icra edememesine yol
                  açmaktadır. Bunlara depresyon veya tükenmişlik sendromu gibi
                  durumlar örnek verilebilir.
                </p>
                <p>
                  İkinci en yaygın neden, iskelet ve hareket sistemi
                  hastalıklarıdır ve bunu kanser hastalıkları takip etmektedir.
                  Buna karşın, bir kaza sonucunda aniden meslek edinemezlik
                  durumu nispeten daha nadirdir.
                </p>
              </div>

              <ul className="space-y-4">
                {statsData.map((data) => (
                  <li className="flex gap-4">
                    <i className="fa-regular fa-square-check text-darkgreen text-lg"></i>
                    <div>
                      <span className="text-darkgreen mr-2">{data.stat}</span>
                      <span>{data.text}</span>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </section>
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 py-16">
            <div className="space-y-4">
              <h2 className="text-2xl font-bold text-darkgreen">
                Meslek Edinemezlik Sigortasında Doğru Bilgi Verme Zorunluluğu
              </h2>
              <p>
                Her sigorta sözleşmesinde olduğu gibi, sigorta müşterisi olarak
                sözleşme yapılırken doğru bilgiler verme zorunluluğunuz vardır.
                Meslek edinemezlik sigortasında, özellikle sağlık durumunuza
                dikkat edilir. Bunun nedeni, olası önceden var olan
                hastalıklarınız veya yaşam tarzınız ve mesleki günlük
                yaşamınızdan kaynaklanan hastalık risklerinin, sigorta şirketi
                için sigorta riskini önemli ölçüde etkileyebilmesidir. Bu yüzden
                başvuru sorularını dikkatli bir şekilde doldurun. Bu durumda,
                BU'ya bir alternatif tercih etmek de önerilebilir.
              </p>
              <p>
                Bu bilgiler aynı zamanda prim miktarı için de önemlidir. Mevcut
                meslek edinemezlik sigortanızı iptal edip başka bir sağlayıcıya
                geçmek isterseniz, genellikle yeni şirkette yeniden bir sağlık
                kontrolü gereklidir. Bu yüzden, bu adımı dikkatlice
                düşünmelisiniz ve iptal mektubunu aceleyle göndermemelisiniz.
                Yeni ve kesin bir teklif alana kadar iptal etmeyin. Giriş
                yaşınız arttıkça, genellikle ödenmesi gereken sigorta primi de
                artar.
              </p>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4 py-16 space-y-16">
            <div className="h-full max-h-max p-6 w-full sm:w-[40%] mx-auto bg-yellow rounded-xl shadow-md space-y-4 relative">
              <img
                src={helpIcon}
                className="absolute w-16 h-16 -top-4 -left-4"
              ></img>
              <h2 className="text-2xl font-semibold text-center text-darkgreen">
                Sağlık Sorularını Mutlaka Doğru Yanıtlayın
              </h2>
              <p className="text-darkgreen">
                Sigortacılar, belirli önceden var olan hastalıklar için sıkça
                risk primi talep etse de, sağlığınıza dair soruları kesinlikle
                doğru yanıtlamanız gerekir. Aksi takdirde, sigorta korumanızı
                riske atarsınız!
              </p>
            </div>
            <Button />
          </div>
        </section>
        {lightbox.open && (
          <div
            className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center p-4"
            style={{ zIndex: "1000000000000000000000000000000000" }}
            onClick={closeLightbox}
          >
            <div className="rounded-md bg-white p-2">
              <img
                className="h-full max-h-[600px] max-w-full rounded-lg object-contain"
                src={lightbox.imgSrc}
                alt="Enlarged gallery photo"
                onClick={(e) => e.stopPropagation()} // Prevent click inside the lightbox from closing it
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default MeslekiYetersizlikSigortası;
