import { useState } from "react";
import { Link } from "react-router-dom";
import Logo from "../../Assets/logo.png";
import NavLinks from "./NavLinks";
import DEVKlogo from "../../Assets/DEVKlogo.png";
export default function Navbar() {
  const [open, setOpen] = useState(false);

  return (
    <>
      <div className="top-0 z-50 bg-darkgreen gap-2 text-white py-2 px-4">
        <div className="mx-auto max-w-7xl flex items-center justify-between">
          <ul className="flex flex-col sm:flex-row sm:gap-12">
            <li className="flex items-center gap-2">
              <p className="text-sm sm:text-xl mr-4">Bize Ulaşın</p>
              <i class="fab fa-whatsapp text-base sm:text-2xl"></i>
              <div className="border-r-2 h-8 mx-4"></div>
              <i class="fa-solid fa-phone-flip text-base sm:text-xl"></i>
            </li>
            <li className="flex items-center gap-2"></li>
          </ul>
          <img
            src={DEVKlogo}
            className="w-auto h-auto max-h-8 sm:max-h-16"
          ></img>
        </div>
      </div>
      <nav className="z-50 container mx-auto flex items-center font-medium justify-between">
        <div className="p-4 md:w-auto w-full flex justify-between items-center">
          <img
            src={Logo}
            alt="logo"
            className="md:cursor-pointer w-auto h-24 md:h-28"
          />
          <div className="text-3xl md:hidden" onClick={() => setOpen(!open)}>
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
        </div>
        <ul className="md:flex hidden uppercase items-center gap-8">
          <li>
            <Link
              to="/"
              className="py-7 px-3 inline-block uppercase hover:text-darkgreen hover:underline"
            >
              Ana Sayfa
            </Link>
          </li>
          <NavLinks />
          <li>
            <Link
              to="/yol-yardimi"
              className="py-7 px-3 inline-block uppercase hover:text-darkgreen hover:underline"
            >
              YOL YARDIMI
            </Link>
          </li>
          <li>
            <Link
              to="/iletisim"
              className="py-7 px-3 inline-block uppercase hover:text-darkgreen hover:underline"
            >
              İletişim
            </Link>
          </li>
          <li>
            <Link
              to="/register"
              className="link px-3 py-2 flex items-center leading-snug text-black hover:opacity-75"
            >
              <button className="bg-darkgreen text-white px-4 py-3 rounded-md">
                Başlamak
              </button>
            </Link>
          </li>
        </ul>

        <div className="md:block hidden"></div>
        {/* Mobile nav */}
        <ul
          className={`z-10
        md:hidden bg-white fixed w-full top-0 overflow-y-auto bottom-0 py-24 pl-4
        duration-500 ${open ? "left-0" : "left-[-100%]"}
        `}
        >
          <div
            className="z-50 text-3xl md:hidden text-end pr-4"
            onClick={() => setOpen(!open)}
          >
            <ion-icon name={`${open ? "close" : "menu"}`}></ion-icon>
          </div>
          <li>
            <Link to="/" className="py-7 px-3 inline-block uppercase">
              Ana Sayfa
            </Link>
          </li>
          <NavLinks />
          <li>
            <Link to="/" className="py-7 px-3 inline-block uppercase">
              YOL YARDIMI
            </Link>
          </li>
          <li>
            <Link to="/" className="py-7 px-3 inline-block uppercase">
              İletişim
            </Link>
          </li>

          <li>
            <Link
              to="/register"
              className="link px-3 py-2 flex items-center text-s leading-snug text-black hover:opacity-75"
            >
              <button className="bg-darkgreen text-white px-4 py-3 rounded-md">
                Başlamak
              </button>
            </Link>
          </li>
        </ul>
      </nav>
      {/* <nav
        ref={navbarRef}
        className="relative flex flex-wrap items-center justify-between px-2 py-3 bg-white border-gray-200 border-b"
      >
        <div className="container mx-auto flex flex-wrap items-center justify-between px-4">
          <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
            <div className="flex flex-col">
              <a
                className="py-2 text-sm font-bold leading-relaxed inline-block whitespace-nowrap uppercase text-black"
                href="/"
              >
                Job Text Maker{" "}
                <button class="ml-2 bg-blue-100 text-blue-500 py-0 px-2 rounded-full font-normal ">
                  Beta
                </button>
              </a>
              <span className="text-sm text-gray-500">
                Jobanzeigen mit Unterstützung von KI erstellen
              </span>
            </div>
            <button
              className="text-black cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none"
              type="button"
              onClick={() => setNavbarOpen(!navbarOpen)}
            >
              <i className="fas fa-bars"></i>
            </button>
          </div>
          <div
            className={
              "lg:flex flex-grow items-center justify-center" +
              (navbarOpen ? " flex" : " hidden")
            }
            id="example-navbar-danger"
          >
            <ul className="flex flex-col lg:flex-row list-none lg:ml-auto items-center justify-center">
              <li className="nav-item">
                <Link
                  to="/"
                  className="link px-3 py-2 flex items-center text-s leading-snug text-black hover:opacity-75"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/faqs"
                  className="link px-3 py-2 flex items-center text-s leading-snug text-black hover:opacity-75"
                >
                  FAQs
                </Link>
              </li>
              <li className="nav-item">
                <Link
                  to="/contact"
                  className="link px-3 py-2 flex items-center text-s leading-snug text-black hover:opacity-75"
                >
                  Kontakt
                </Link>
              </li>

              {!user?.uid && (
                <li className="nav-item">
                  <Link
                    to="/login"
                    className="link px-3 py-2 flex items-center text-s leading-snug text-black hover:opacity-75"
                  >
                    Anmeldung
                  </Link>
                </li>
              )}

              {!user?.uid && (
                <li className="nav-item">
                  <Link
                    to="/register"
                    className="link px-3 py-2 flex items-center text-s leading-snug text-black hover:opacity-75"
                  >
                    <button className="bg-emerald-500 text-white px-4 py-2 rounded-md">
                      Registrieren
                    </button>
                  </Link>
                </li>
              )}

              {user?.uid && (
                <li>
                  <div className="relative" onClick={toggleDropdown}>
                    <button className="flex items-center bg-white rounded-full p-2">
                      <img
                        src={user.photoURL ? user.photoURL : user_img}
                        alt="Admin profile image placeholder"
                        className="rounded-full mr-2 w-8 h-8 object-fill"
                      />
                    </button>
                    <Dropdown
                      isOpen={isOpen}
                      toggle={toggleDropdown}
                      ref={profileRef}
                    />
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </nav> */}
    </>
  );
}
