import React, { useEffect, useState, useRef } from "react";

import ACV_Logo from "../../Assets/slideImg2.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TimingsSection = () => {
  const schedules = [
    { day: "Pazartesi", hours: "09:00 - 18:00" },
    { day: "Salı", hours: "09:00 - 18:00" },
    { day: "Çarşamba", hours: "09:00 - 18:00" },
    { day: "Perşembe", hours: "09:00 - 18:00" },
    { day: "Cuma", hours: "09:00 - 13:00" },
  ];

  return (
    <div className="container mx-auto px-4 py-16">
      <h2 className="text-4xl text-green font-bold mb-4">
        Müşteri Hizmetleri Açılış Saatleri
      </h2>
      <p className="text-gray-700">
        bizi telefonla ve WhatsApp üzerinden ulaşabilirsiniz.
      </p>
      <div className="flex flex-wrap flex-row items-center sm:items-start py-4">
        {schedules.map((schedule, index) => (
          <div
            key={index}
            className="md:min-w-[140px] bg-darkgreen text-white rounded-tr-xl p-4 m-2 flex flex-col items-center justify-center hover:opacity-75 space-y-2"
          >
            <i className="far fa-clock text-lg"></i>
            <span className="font-bold">{schedule.day}</span>
            <span>{schedule.hours}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [service, setService] = useState("");

  const [errors, setErrors] = useState({
    name: false,
    email: false,
    phone: false,
    service: false,
  });

  const saveToGoogleSheets = async (data) => {
    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Accept", "*/*");
      myHeaders.append("X-Requested-With", "XMLHttpRequest");

      var requestOptions = {
        method: "post",
        headers: myHeaders,
        body: JSON.stringify(data),
      };

      fetch(
        "https://google-sheets-integration.onrender.com/api/addRow",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.log("error", error));
    } catch (error) {
      console.error("Error saving data to Google Sheets:", error);
      toast.error("Error saving form data");
    }
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    let formIsValid = true;

    const requiredFields = {
      name,
      email,
      phone,
      service,
    };

    const newErrors = { ...errors };

    // Check for empty fields
    Object.keys(requiredFields).forEach((field) => {
      if (!requiredFields[field]) {
        formIsValid = false;
        newErrors[field] = true;
      } else if (field === "phone" && phone.length !== 14) {
        // Specifically for phone, check if the length is exactly 14 digits (space also is counted)
        formIsValid = false;
        newErrors[field] = true;
      } else {
        newErrors[field] = false;
      }
    });

    setErrors(newErrors);

    if (formIsValid) {
      try {
        // // google sheets integration
        // var currentDate = new Date();
        // const data = {
        //   Name: name,
        //   "Phone Number": phone,
        //   Email: email,
        //   Service: service,
        //   Date: currentDate.toLocaleString("en-US", {
        //     timeZone: "America/New_York",
        //   }),
        // };
        // saveToGoogleSheets(data);

        toast.success("Form submitted successfully!", { id: "ok" });
        window.location.href = "/contact-us";
        setName("");
        setEmail("");
        setPhone("");
      } catch (error) {
        // toast.error(error);
      }
    }
  };

  const handlePhoneChange = (e) => {
    const input = e.target.value.replace(/\D/g, ""); // Remove all non-digit characters
    let formattedInput = "";

    // Split the input into parts for area code, exchange code, and subscriber number
    if (input.length > 0) {
      formattedInput += `(${input.substring(0, 3)}`; // Area code: (555
    }
    if (input.length >= 4) {
      formattedInput += `) ${input.substring(3, 6)}`; // Exchange code: ) 555-
    }
    if (input.length > 6) {
      formattedInput += `-${input.substring(6, 10)}`; // Subscriber number: 5555
    }

    // Update the state with the formatted input
    setPhone(formattedInput);

    // Optionally, update the error state based on the input's length
    setErrors({
      ...errors,
      phone: input.length < 10, // Consider it an error if the length is less than 10 digits
    });
  };

  return (
    <div>
      <ToastContainer></ToastContainer>
      <div className="container mx-auto px-2 pb-12">
        <section className="mx-auto max-w-md">
          <div className="py-4 px-2 md:p-4 rounded-sm border border-gray-400">
            <h2 className="text-2xl font-bold  text-center mb-4">
              GET IN TOUCH TODAY!
            </h2>
            <form onSubmit={handleFormSubmit}>
              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="name"
                >
                  Your Name{" "}
                  {errors.name && (
                    <small className="text-red-500">* Required</small>
                  )}
                </label>
                <input
                  id="name"
                  name="name"
                  type="text"
                  placeholder="JOHN DOE"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                    setErrors({ ...errors, name: false });
                  }}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline ${
                    errors.name ? "border-red-500" : "border-gray-300"
                  } rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
                />
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="phone"
                >
                  Your Phone Number{" "}
                  {errors.phone && (
                    <small className="text-red-500">* Required</small>
                  )}
                </label>
                <input
                  id="phone"
                  type="phone"
                  name="phone"
                  placeholder="(123) 456-7890"
                  value={phone}
                  onChange={handlePhoneChange}
                  // onChange={(e) => {
                  //   setPhone(e.target.value);
                  //   setErrors({ ...errors, phone: false });

                  // }}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline ${
                    errors.phone ? "border-red-500" : "border-gray-300"
                  } rounded w-full py-2 px-3 text-gray-700 focus:outline-none focus:shadow-outline`}
                />
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Email{" "}
                  {errors.email && (
                    <small className="text-red-500">* Required</small>
                  )}
                </label>
                <input
                  id="email"
                  type="email"
                  name="email"
                  placeholder="JOHN.DOE@GMAIL.COM"
                  value={email}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    setErrors({ ...errors, email: false });
                  }}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline ${
                    errors.email ? "border-red-500" : "border-gray-300"
                  }`}
                />
              </div>

              <div className="mb-4">
                <label
                  className="block text-gray-700 text-sm font-bold mb-2"
                  htmlFor="email"
                >
                  Select A Service{" "}
                  {errors.service && (
                    <small className="text-red-500">* Required</small>
                  )}
                </label>
                <select
                  id="service"
                  name="service"
                  value={service}
                  onChange={(e) => {
                    setService(e.target.value);
                    setErrors({ ...errors, service: false });
                  }}
                  className={`shadow appearance-none border rounded w-full py-2 px-3 text-gray-700  focus:outline-none focus:shadow-outline ${
                    errors.service ? "border-red-500" : "border-gray-300"
                  }`}
                >
                  <option value="">Select</option>
                  {[
                    "Commercial and Residential Roofing",
                    "Repairs and 24/7 Emergency Repairs",
                    "Vinyl and Cement Decorative Siding",
                    "Lifetime Krypton Windows and Doors",
                    "Bulletproof war and storm shelters",
                    "Epoxy Concrete Flooring Systems",
                    "Custom Design Decks and Fencing",
                    "Cleaning & Powerwashing",
                    "Complete Insurance Claim Process",
                    "Apply for Financing",
                    "Apply for Job",
                  ].map((serviceOption, index) => (
                    <option key={index} value={serviceOption}>
                      {serviceOption}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mt-8 flex">
                <button
                  className="w-full bg-darkgreen text-white font-semibold hover:bg-darkred px-4 py-2.5 rounded"
                  type="submit"
                >
                  Submit Form
                </button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </div>
  );
};

function Iletism() {
  return (
    <>
      <div class="bg-white">
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <div className="space-y-4">
                  <h2 className="text-4xl font-bold text-darkgreen">
                    Iletisim
                  </h2>
                  <p>
                    Sigortanız olarak sizden haber almak bizim için her zaman
                    bir mutluluk. İster ürünlerimiz hakkında sorularınız olsun,
                    ister danışmanlık ihtiyacınız olsun ya da mevcut bir konuda
                    destek arıyor olun – ekibimiz size yardımcı olmak için
                    burada.
                  </p>
                </div>
              </div>
              <div class="aspect-w-16 aspect-h-9">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2512.2752160558084!2d6.965776275617749!3d50.9741033495233!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47bf2f62e49d3c57%3A0x72e9d2dc08ace65e!2sFriedrich-Karl-Stra%C3%9Fe%20270%2C%2050735%20K%C3%B6ln%2C%20Germany!5e0!3m2!1sen!2s!4v1715097167647!5m2!1sen!2s"
                  width="100%"
                  // height="400"
                  style={{ border: "0" }}
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  className="h-[240px] sm:h-[280px] md:h-[400px]"
                ></iframe>
              </div>
            </div>
          </div>
        </section>
        <section>
          <TimingsSection />
        </section>
        <section className="bg-lightgray">
          <div class="container grid sm:grid-cols-2 gap-2 px-4 py-16 mx-auto text-gray-800">
            <div>
              <h2 class="text-4xl font-bold">İletişim Bilgileri</h2>
              <p className="text-sm text-gray-500 mt-4">
                {" "}
                Contact us now on any below details
              </p>
              <div class="mt-8">
                <ul class="mt-3">
                  <li class="flex items-center mb-8">
                    <div class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                      <i class="fa-solid fa-envelope text-darkgreen"></i>
                    </div>
                    <a
                      href="mailto:admin@UnitedStatesRoofing.com"
                      class="text-darkgreen text-sm ml-3"
                    >
                      <small class="block">E-posta ile</small>
                      <strong>birkan.kati@vtp.devk.de</strong>
                    </a>
                  </li>
                  <li class="flex items-center mb-8">
                    <div class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                      <i class="fa-solid fa-location-dot text-darkgreen"></i>
                    </div>
                    <a
                      href="javascript:void(0)"
                      class="text-darkgreen text-sm ml-3"
                    >
                      <small class="block">Adres</small>
                      <strong>Friedrich-Karl-Str. 270 – 50735 Köln</strong>
                    </a>
                  </li>
                  <li class="flex items-center mb-8">
                    <div class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                      <i class="fa-solid fa-phone-flip text-darkgreen"></i>
                    </div>
                    <a
                      href="tel:02218694918"
                      class="text-darkgreen text-sm ml-3"
                    >
                      <small class="block">Telefon numarası</small>
                      <strong>02218694918</strong>
                    </a>
                  </li>
                  <li class="flex items-center mb-8">
                    <div class="bg-[#e6e6e6cf] h-10 w-10 rounded-full flex items-center justify-center shrink-0">
                      <i class="fa-brands fa-whatsapp text-xl font-bold text-darkgreen"></i>
                    </div>
                    <a href="" class="text-darkgreen text-sm ml-3">
                      <small class="block">WhatsApp numarası</small>
                      <strong>0176 56901923</strong>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <ContactForm />
          </div>
        </section>
      </div>
    </>
  );
}

export default Iletism;
