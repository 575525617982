import React, { useEffect, useState, useRef } from "react";
import "./YolYardimi.css";
import ACV_Logo from "../../Assets/ACV_Logo_Claim_Unten_RGB_orange_anthrazit.png";
import DEVKlogo from "../../Assets/DEVKlogo.png";
import whatappLogo from "../../Assets/whatsapp.png";
import helpIcon from "../../Assets/help-icon.png";
const faqsData = [
  {
    question: "Sigortalı Araçlar",
    answer: (
      <div className="relative shadow-sm sm:rounded-lg ">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="width-th"></th>
              <th scope="col" className="width-td"></th>
              <th scope="col" className="width-td"></th>
            </tr>
          </thead>
          <tbody>
            {[
              { name: "Otomobiller", icon1: "check", icon2: "check" },
              {
                name: "Bisikletler, pedelecler, yük bisikletleri, yatay bisikletler ve bisiklet römorkları",
                icon1: "check",
                icon2: "check",
              },
              { name: "Motosikletler", icon1: "check", icon2: "check" },
              {
                name: "Sigorta zorunluluğu olan motorlu taşıtlar (örneğin, Mofa veya scooter)",
                icon1: "check",
                icon2: "check",
              },
              { name: "Römorklar", icon1: "check", icon2: "check" },
              {
                name: "4 tona kadar karavanlar",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "7,5 tona kadar karavanlar",
                icon1: "times",
                icon2: "check",
              },
              {
                name: "3,5 tona kadar kamyonlar",
                icon1: "times",
                icon2: "check",
              },
            ].map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0
                    ? "bg-gray-50 dark:bg-gray-800"
                    : "bg-white dark:bg-gray-900"
                } border-b dark:border-gray-700`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {row.name}
                </th>

                <td className="px-6 py-4 text-center">
                  {row.icon1 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
                <td className="px-6 py-4 text-center">
                  {row.icon2 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
  },
  {
    question: "Arıza ve Kaza Durumunda Yardım",
    answer: (
      <div className="relative shadow-sm sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="width-th"></th>
              <th scope="col" className="width-td"></th>
              <th scope="col" className="width-td"></th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                name: "Avrupa Genelinde Arıza ve Kaza Durumunda Yardım",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Dünya Genelinde Arıza ve Kaza Durumunda Yardım",
                icon1: "times",
                icon2: "check",
              },
              {
                name: "En yakın uzman servise veya şarj istasyonuna çekilme",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Tercih edilen servise veya şarj istasyonuna çekilme",
                icon1: "times",
                icon2: "check",
              },
              { name: "Konaklama", icon1: "check", icon2: "check" },
              {
                name: "Evden 50 km uzaklıkta başlayan devam veya dönüş yolculuğu",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Evden Başlayan Hareketlilik Hizmeti",
                icon1: "times",
                icon2: "check",
              },
              {
                name: "Araç Kurtarma Hizmeti",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Yanlış Yakıt Dolumu Durumunda Yardım",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Araç Kilit Açma Hizmeti",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Araç Geri Taşıma Hizmeti",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Araç Alma Hizmeti",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Yedek Parça Gönderimi",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Hurda ve Gümrükleme",
                icon1: "check",
                icon2: "check",
              },
            ].map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0
                    ? "bg-gray-50 dark:bg-gray-800"
                    : "bg-white dark:bg-gray-900"
                } border-b dark:border-gray-700`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {row.name}
                </th>

                <td className="px-6 py-4 text-center">
                  {row.icon1 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
                <td className="px-6 py-4 text-center">
                  {row.icon2 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
  },
  {
    question: "Hastalık veya Yaralanma Durumunda",
    answer: (
      <div className="relative shadow-sm sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="width-th"></th>
              <th scope="col" className="width-td"></th>
              <th scope="col" className="width-td"></th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                name: "Doktor Bakımı Yönlendirme",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Görme Yardımcıları ve İlaçların Gönderimi",
                icon1: "check",
                icon2: "check",
              },
              { name: "Hasta Ziyareti", icon1: "check", icon2: "check" },
              {
                name: "Hasta Geri Taşıma",
                icon1: "check",
                icon2: "check",
              },
              { name: "Ölüm Durumunda Yardım", icon1: "check", icon2: "check" },
              {
                name: "Karantina Durumunda Yardım",
                icon1: "times",
                icon2: "check",
              },
              {
                name: "Çocukların Geri Getirilmesi",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Çocuk Bakımı",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Hayvanların Geri Taşınması",
                icon1: "check",
                icon2: "check",
              },
            ].map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0
                    ? "bg-gray-50 dark:bg-gray-800"
                    : "bg-white dark:bg-gray-900"
                } border-b dark:border-gray-700`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {row.name}
                </th>

                <td className="px-6 py-4 text-center">
                  {row.icon1 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
                <td className="px-6 py-4 text-center">
                  {row.icon2 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
  },
  {
    question: "Yoldayken Hizmet",
    answer: (
      <div className="relative shadow-sm sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="width-th"></th>
              <th scope="col" className="width-td"></th>
              <th scope="col" className="width-td"></th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                name: "Avrupa genelinde 24/7 acil durum çağrı merkezi",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Seyahat Belgelerinin Yenilenmesi",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Banka Kartı Kaybı Durumunda Yardım",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Araç Anahtarı Hizmeti",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Seyahat Kesilmesi Durumunda Geri Dönüş",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Seyahat Acentasının İflası Durumunda Yardım",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Doğal Afet Durumunda Yardım",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Hukuki Yardımın Yönlendirilmesi",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Ustaların Bulunması",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Ev Bakıcısı Bulunması",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Özel Acil Durumlarda Yardım",
                icon1: "check",
                icon2: "check",
              },
            ].map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0
                    ? "bg-gray-50 dark:bg-gray-800"
                    : "bg-white dark:bg-gray-900"
                } border-b dark:border-gray-700`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {row.name}
                </th>

                <td className="px-6 py-4 text-center">
                  {row.icon1 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
                <td className="px-6 py-4 text-center">
                  {row.icon2 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
  },
  {
    question: "Bisiklet Turlarında Yardım",
    answer: (
      <div className="relative shadow-sm sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="width-th"></th>
              <th scope="col" className="width-td"></th>
              <th scope="col" className="width-td"></th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                name: "Arıza Yardımı",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Fahrradwerkstatt'a Taşıma Hizmeti",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Devam veya Geri Dönüş ",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Premium Devam veya Geri Dönüş Seyahati",
                icon1: "times",
                icon2: "check",
              },
              {
                name: "Yedek Bisiklet",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Yedek Bisiklet Premium",
                icon1: "times",
                icon2: "check",
              },
              {
                name: "Konaklama",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Konaklama Premium",
                icon1: "times",
                icon2: "check",
              },
              {
                name: "Bisikletin Kurtarılması",
                icon1: "times",
                icon2: "check",
              },
              {
                name: "Bisikletin Geri Taşınması",
                icon1: "times",
                icon2: "check",
              },
              {
                name: "Gümrükleme veya Hurdaya Ayırma",
                icon1: "times",
                icon2: "check",
              },
            ].map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0
                    ? "bg-gray-50 dark:bg-gray-800"
                    : "bg-white dark:bg-gray-900"
                } border-b dark:border-gray-700`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {row.name}
                </th>

                <td className="px-6 py-4 text-center">
                  {row.icon1 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
                <td className="px-6 py-4 text-center">
                  {row.icon2 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
  },
  {
    question: "Diğer Hizmetler",
    answer: (
      <div className="relative shadow-sm sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="width-th"></th>
              <th scope="col" className="width-td"></th>
              <th scope="col" className="width-td"></th>
            </tr>
          </thead>
          <tbody>
            {[
              {
                name: "Hukuki İlk Danışmanlık",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Trafik Kazası Sigortası",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Tur Danışmanlığı",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Finansal Kulüp Yardımı",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Araç Değerlendirme",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "Özel Ortak Teklifler",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "ACV App",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "ACV Şarj Kartı",
                icon1: "check",
                icon2: "check",
              },
              {
                name: "İndirimli Sürüş Güvenliği Eğitimi",
                icon1: "check",
                icon2: "check",
              },
            ].map((row, index) => (
              <tr
                key={index}
                className={`${
                  index % 2 === 0
                    ? "bg-gray-50 dark:bg-gray-800"
                    : "bg-white dark:bg-gray-900"
                } border-b dark:border-gray-700`}
              >
                <th
                  scope="row"
                  className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                >
                  {row.name}
                </th>

                <td className="px-6 py-4 text-center">
                  {row.icon1 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
                <td className="px-6 py-4 text-center">
                  {row.icon2 === "check" ? (
                    <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                  ) : (
                    <i className="fa-solid fa-times text-red-500 text-lg"></i>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ),
  },
];

export const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // If clicked again, close it
    } else {
      setOpenIndex(index); // Else, open the clicked one
    }
  };

  return (
    <div className="container mx-auto">
      <div className="pb-12 px-4">
        {faqsData.map((faq, index) => (
          <div key={index} className="">
            <button
              onClick={() => toggleFAQ(index)}
              className="flex justify-between items-center w-full px-4 py-8 text-left rounded-t-md hover:opacity-90 border-b border-gray-300 bg-white"
            >
              <span className="font-semibold text-lg">{faq.question}</span>
              <span>
                {openIndex === index ? (
                  <i class="fa-solid fa-chevron-up"></i>
                ) : (
                  <i class="fa-solid fa-chevron-down"></i>
                )}
              </span>
            </button>
            {openIndex === index && <div className="">{faq.answer}</div>}
          </div>
        ))}
      </div>
    </div>
  );
};

function YolYardimi() {
  const rows = [
    {
      title: "Eşiniz için Koruma",
      checkmarks: 2, // Number of checkmarks needed in this row
    },
    {
      title: "Reşit olmayan çocuklar için Koruma",
      checkmarks: 2,
    },
    {
      title: "Kendi araçlarında yabancı sürücüler için Koruma",
      checkmarks: 2,
    },
  ];

  return (
    <>
      <div class="bg-white">
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <div className="space-y-2">
                  <h2 className="text-2xl font-bold text-darkgreen">
                    Almanya'da Yol yardım sigortası!
                  </h2>
                  <p>
                    Kooperasyon ortağımız ACV ile birlikte, size otomobil
                    arızalarında güvenilir bir yardımcı sunabilmekten mutluluk
                    duyuyoruz. ACV Konfor Tarifesi, arıza durumunda hızlı yardım
                    anlamına gelmekte ve seyahat planlarınızı destekleyen geniş
                    kapsamlı hizmetler paketi sunarak, cazip bir fiyata mobilite
                    imkanı sağlamaktadır.
                  </p>
                </div>
                <div className="mt-8 space-y-2">
                  <h2 className="text-xl font-semibold text-mediumorange">
                    ACV Premium Tarife
                  </h2>
                  <p>
                    Hiçbir kompromis yapmak istemeyenler için, ACV Premium
                    Tarifesi'ni sunuyoruz. Bu, hizmetlerimiz yelpazesini ekstra
                    avantajlarla genişleterek, her durumda en iyi şekilde
                    güvende olmanızı sağlar.
                  </p>
                </div>
              </div>
              <div class="aspect-w-16 aspect-h-9 mx-auto">
                <img
                  src={ACV_Logo}
                  alt=""
                  className="w-auto h-auto max-h-[400px]"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="mx-auto bg-lightgray w-full max-w-[280px] rounded-full flex flex-col items-center gap-2 p-4 hover:bg-gray-200 cursor-pointer">
                <img src={whatappLogo} className="self-end mr-8 w-8 h-8"></img>

                <span className="text-center text-darkgreen font-bold">
                  Teklif için buraya tıklayın
                </span>
                <div className="flex gap-2">
                  <img src={DEVKlogo} className="w-full h-12"></img>
                  <div className="flex flex-col border-gray-600 pl-2 border-l text-darkgreen font-bold">
                    <span className="text-xs">Gesagt.</span>
                    <span className="text-xs">Getan.</span>
                    <span className="text-xs">Geholfen.</span>
                  </div>
                </div>
              </div>
              <div>
                <div className="flex flex-col space-y-4">
                  <img
                    src={helpIcon}
                    className="self-end mr-8 w-8 h-8"
                    style={{ transform: "rotate(15deg)" }}
                  ></img>
                  <p>
                    Bizim uzmanlığımıza ve ACV'nin kalitesine güvenin, güvenlik
                    ve huzur içinde yolda olmanın keyfini çıkarın. Sunmuş
                    olduğumuz tarif seçeneklerini keşfedin ve ihtiyaçlarınıza en
                    uygun çözümü seçin.
                  </p>
                  <p>
                    Herhangi bir sorunuz olursa, her zaman size yardımcı olmaya
                    hazırız. Memnuniyetiniz ve mobilite ihtiyacınız bizim için
                    önemlidir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 pt-8">
            <div class="relative shadow-sm sm:rounded-lg">
              <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                <thead class="text-xs text-gray-700 uppercase dark:bg-gray-700 dark:text-gray-400">
                  <tr>
                    <th scope="col">
                      {" "}
                      <div class="tarifCompareGroup__head-item--spacer"></div>
                    </th>
                    <th scope="col">
                      <div
                        class="js-tarif-compare-change-tarif-head tarifCompareGroup__head-item "
                        data-index="0"
                      >
                        <div
                          class="tarifCompareGroup__tarif-name"
                          data-type="name"
                        >
                          <span>ACV </span>
                          <span>Komfort</span>
                        </div>
                        <div class="tarifCompareGroup__price">
                          <div class="tarifCompareGroup__price-value">69</div>
                          <div class="tarifCompareGroup__price-label">
                            € / Yilik
                          </div>
                        </div>
                        <div class="hidden" data-type="subhead">
                          Die Sorglos-Mitgliedschaft für alle, die im Alltag
                          immer mobil bleiben wollen.
                        </div>
                      </div>
                    </th>
                    <th scope="col">
                      <div
                        class="js-tarif-compare-change-tarif-head tarifCompareGroup__head-item visible"
                        data-index="1"
                      >
                        <div
                          class="tarifCompareGroup__tarif-name"
                          data-type="name"
                        >
                          <span>ACV </span>
                          <span>Premium</span>
                        </div>
                        <div class="tarifCompareGroup__price">
                          <div class="tarifCompareGroup__price-value">109</div>
                          <div class="tarifCompareGroup__price-label">
                            € / Yilik
                          </div>
                        </div>
                        <div class="hidden" data-type="subhead">
                          Die Premium-Mitgliedschaft für alle, die das Extra an
                          Mobilität wünschen.
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                {/* <tbody>
                  <tr class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700">
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Eşiniz için Koruma
                    </th>
                    <td class="px-6 py-4 text-center">
                      <i class="fa-solid fa-check text-darkgreen text-lg"></i>
                    </td>
                    <td class="px-6 py-4 text-center">
                      <i class="fa-solid fa-check text-darkgreen text-lg"></i>
                    </td>
                  </tr>
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Reşit olmayan çocuklar için Koruma
                    </th>
                    <td class="px-6 py-4 text-center">
                      {" "}
                      <i class="fa-solid fa-check text-darkgreen text-lg"></i>
                    </td>
                    <td class="px-6 py-4 text-center">
                      {" "}
                      <i class="fa-solid fa-check text-darkgreen text-lg"></i>
                    </td>
                  </tr>
                </tbody> */}
                <tbody className="bg-white">
                  {rows.map((row, index) => (
                    <tr
                      key={index}
                      className={`${
                        index % 2 === 0
                          ? "bg-gray-50 dark:bg-gray-800"
                          : "bg-white dark:bg-gray-900"
                      } border-b dark:border-gray-700`}
                    >
                      <th
                        scope="row"
                        className="px-6 py-4 font-medium text-gray-900 dark:text-white"
                      >
                        {row.title}
                      </th>
                      {Array.from({ length: row.checkmarks }, (_, idx) => (
                        <td key={idx} className="px-6 py-4 text-center">
                          <i className="fa-solid fa-check text-darkgreen text-lg"></i>
                        </td>
                      ))}
                    </tr>
                  ))}
                  <tr>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      Indirimler
                    </th>
                    <td class="px-6 py-4  sm:max-w-full max-w-[9rem]">
                      {" "}
                      <i class="fa-solid fa-check text-darkgreen text-lg mr-2"></i>
                      Bekârlar için 59 EUR
                    </td>
                    <td class="px-6 py-4 sm:max-w-full max-w-[9rem]">
                      {" "}
                      <i class="fa-solid fa-check text-darkgreen text-lg mr-2"></i>
                      Bekârlar için 99 EUR
                    </td>
                  </tr>
                  <tr  className={`border-b`}>
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    ></th>
                    <td class="px-6 py-4 sm:max-w-full max-w-[9rem]">
                      {" "}
                      <i class="fa-solid fa-check text-darkgreen text-lg mr-2"></i>
                      23 yaşına kadar gençler için 49 €
                    </td>
                    <td class="px-6 py-4 sm:max-w-full max-w-[9rem]">
                      {" "}
                      <i class="fa-solid fa-check text-darkgreen text-lg mr-2"></i>
                      23 yaşına kadar gençler için 89 €
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <FAQs />
        </section>
      </div>
    </>
  );
}

export default YolYardimi;
