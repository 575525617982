import React, { useEffect, useState, useRef } from "react";

import ACV_Logo from "../../Assets/ACV_Logo_Claim_Unten_RGB_orange_anthrazit.png";
import DEVKlogo from "../../Assets/DEVKlogo.png";
import financeImg from "../../Assets/Design ohne Titel (13).png";
import dangerLogo from "../../Assets/Design ohne Titel (14).png";

import ilkAracImg1 from "../../Assets/ilk-arac-img1.png";
import ilkAracImg2 from "../../Assets/ilk-arac-img2.png";
import ilkAracImg3 from "../../Assets/ilk-arac-img3.png";
import helpIcon from "../../Assets/help-icon.png";

import icon1 from "../../Assets/icon1.png";
import icon2 from "../../Assets/icon2.png";
import icon3 from "../../Assets/icon3.png";
import icon4 from "../../Assets/icon4.png";
import Button from "../Button/Button";

const faqsData = [
  {
    question: "Dinamik:",
    answer: `Enflasyonu veya zamanla artan mali talepleri dengelemek için bir dinamik seçeneği tercih edilebilir. Bu durumda, sigorta tutarı yıllık olarak belirli bir yüzde oranında artar. Ancak, tutarın artmasıyla paralel olarak prim de genellikle yıllık olarak artar. Bu artışa genellikle en az iki yıl üst üste itiraz edilebilir. Uzun vadeli sözleşmeler ve sabit sigorta tutarı için dinamik, mantıklı olabilir.`,
  },
  {
    question: "Önceden Belirlenmiş Vefat Ödeneği:",
    answer: `Eğer ciddi bir şekilde hastalanırsanız ve ölüm tarihi öngörülebilir hale gelirse (genellikle önümüzdeki on iki ay içinde), önceden belirlenmiş vefat ödeneği içeren tarifelerde, sigorta tutarının bir kısmı ölmeden önce ödenir. Bu tür teşhisler pratikte son derece nadir görülse de, böyle bir durumda, önceden belirlenmiş vefat ödeneği, sigortalının mali yükünü hafifletebilir.`,
  },
  {
    question: "Ek Sigorta Garantisi:",
    answer: `Bir çocuğun doğumu veya bir emlak alımı gibi gelecekteki olaylara hazırlıklı olmak için, ek sigorta garantisi içeren bir poliçe faydalıdır. Bu, sigorta tutarının, yeniden sağlık kontrolü yapılmadan sonradan artırılabileceği anlamına gelir. Genellikle artış sınırlıdır - çoğunlukla olay başına 20.000 ila 50.000 Euro arası - ve sadece belirli durumlar için mümkündür.

Özel tarifeler, genişletilmiş bir ek sigorta garantisi de sunar. Böylece, tutar, bir serbest meslek başlangıcı gibi özel olaylarda veya belirli bir neden olmaksızın artırılabilir.

Ek sigorta garantisi – aynı zamanda artırma opsiyonu olarak da adlandırılır – genellikle genç çiftler için mantıklıdır.`,
  },
  {
    question: "Ağır Hastalıklarda Ek Hizmetler:",
    answer: `Hemen ölümle sonuçlanmayan ciddi hastalıklar söz konusu olduğunda, bazı sigortacılar müşterilerine ek bir ödeme seçeneği sunmaktadır. Örneğin, bir sigortalıya kanser teşhisi konduğunda, sigorta, belirli bir maksimum sınır kadar olmak üzere vefat ödeneğinin bir kısmını öder. Kalp krizi veya felç gibi durumlarda da bu tür anında ödemeler mümkündür. Ödeme, vefat ödeneğini azaltmaz ve bu nedenle gerçek bir ek hizmettir.`,
  },
];

export const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // If clicked again, close it
    } else {
      setOpenIndex(index); // Else, open the clicked one
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      {faqsData.map((faq, index) => (
        <div key={index} className="">
          <button
            onClick={() => toggleFAQ(index)}
            className="flex justify-between items-center w-full py-6 text-left border-b border-gray-300"
          >
            <span
              className={
                openIndex === index
                  ? "text-black font-semibold"
                  : "text-darkgreen font-semibold"
              }
            >
              {faq.question}
            </span>
            <span>
              {openIndex === index ? (
                <i class="fa-solid fa-chevron-up text-sm text-darkgreen"></i>
              ) : (
                <i class="fa-solid fa-chevron-down text-sm text-darkgreen"></i>
              )}
            </span>
          </button>
          {openIndex === index && (
            <div className="py-6 border-b border-gray-300 text-gray-500">
              <pre>{faq.answer}</pre>
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

function RiskHayatSigortasi() {
  const [lightbox, setLightbox] = useState({ open: false, imgSrc: "" });
  const openLightbox = (imgSrc) => {
    setLightbox({ open: true, imgSrc });
  };

  const closeLightbox = () => {
    setLightbox({ open: false, imgSrc: "" });
  };

  return (
    <>
      <div class="bg-white">
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <div className="space-y-4">
                  <h2 className="text-2xl font-bold text-darkgreen">
                    Risk Hayat Sigortasi
                  </h2>
                  <div>
                    <p>Özetle En Önemliler</p>
                    <ul className="mt-2 list-disc pl-5 space-y-2">
                      {[
                        "Bir risk hayat sigortası, vefat durumunda, yakınlarını mali olarak güvence altına almak için belirlenen bir meblağı öder.",
                        "Özellikle çocuklu ebeveynler, ana gelir sahibi olan çiftler, emlak alıcıları ve iş kurucular için son derece mantıklıdır.",
                        "Bir kural olarak, sigortalı kişinin brüt yıllık gelirinin üç ila beş katı kadar bir sigorta tutarı belirlenmelidir.",
                        "Sigorta, yakınlar destek ihtiyacı duyduğu sürece veya bir kredi ödenene kadar devam etmelidir.",
                      ].map((option) => (
                        <li className="dotted pl-4">{option}</li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div class="aspect-w-16 aspect-h-9 mx-auto">
                <img
                  src={ilkAracImg1}
                  alt=""
                  className="w-full h-full max-h-[400px] cursor-pointer"
                  onClick={() => openLightbox(ilkAracImg1)}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="aspect-w-16 aspect-h-9 mx-auto order-2 md:order-1">
                <img
                  src={financeImg}
                  alt=""
                  className="w-full h-full max-h-[350px] cursor-pointer"
                  onClick={() => openLightbox(financeImg)}
                />
              </div>
              <div className="order-1 md:order-2">
                <div className="space-y-4">
                  <h2 className="text-2xl font-bold text-darkgreen">
                    Risk Hayat Sigortasi
                  </h2>
                  <p>
                    Risk hayat sigortasının en önemli hizmeti, vefat durumunda
                    belirlenen bir para miktarının ödenmesidir. Sigortalı kişi
                    poliçenin süresi içinde vefat ederse, sigortacı bu sigorta
                    tutarını bir kerelik olarak hak sahibi kişiye öder. Bu
                    tutarın ne kadar olacağı, korumanın kaç yıl süreceği ve
                    paranın kim tarafından alınacağı, sözleşme yapılırken
                    belirlenir.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <div className="space-y-4">
                  <h2 className="text-2xl font-bold text-darkgreen">
                    Kimler İçin Risk Hayat Sigortası Mantıklıdır
                  </h2>
                  <p>
                    Eşiniz, çocuğunuz veya iş ortağınız mali olarak size
                    bağlıysa, bir risk hayat sigortası mantıklıdır.
                  </p>
                  <p>
                    Bu sigorta, yakınlarınızın veya iş ortaklarınızın mali
                    olarak güvence altına alındığından emin olmanızı sağlar.
                  </p>
                  <div>
                    <p>
                      Özellikle aşağıdaki gruplar için bir poliçe tavsiye
                      edilir:
                    </p>
                    <ul className="my-2 space-y-2">
                      {[
                        "Çocuklu ebeveynler",
                        "Ana gelir sahibi olan çiftler",
                        "Kredi ile emlak alan alıcılar",
                        "İş veya şirket kuranlar",
                      ].map((option) => (
                        <li className="flex gap-4">
                          <i className="fa-regular fa-square-check text-darkgreen text-lg"></i>
                          <span className="font-semibold">{option}</span>
                        </li>
                      ))}
                    </ul>
                    <p>
                      Diğer yandan, bakmakla yükümlü olmadıkları aile üyeleri
                      olmayan bekarlar, genellikle risk hayat sigortasına
                      ihtiyaç duymazlar.
                    </p>
                  </div>
                </div>
              </div>
              <div class="aspect-w-16 aspect-h-9 mx-auto">
                <img
                  src={dangerLogo}
                  alt=""
                  className="w-full h-full max-h-[300px] cursor-pointer"
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div className="flex-col space-y-4">
                <h2 className="text-2xl font-bold text-darkgreen">
                  Mantıklı Ek Hizmetler
                </h2>
                <p>
                  Birçok sigortacı, temel korumanın yanı sıra, ya bir tarifte
                  zaten bulunan ya da poliçe yapılırken ek olarak
                  belirlenebilecek ek hizmetler sunmaktadır. Bu ek hizmetler,
                  uzun sözleşme sürelerine rağmen sözleşmelerin esnek ve
                  bireysel bir şekilde düzenlenmesine olanak tanır.
                </p>
                <p>Sıkça belirlenebilen ek hizmetler şunlardır:</p>
                <FAQs />
              </div>

              <Button />
            </div>
          </div>
        </section>

        <section
          style={{
            height: "100%",
            background: "linear-gradient(180deg, #000000 50%, #e8f2e4 50%)",
          }}
        >
          <div className="container mx-auto px-4 py-16">
            <h2 className="text-2xl font-bold text-darkgreen pb-8 sm:pb-0">
              Risk Hayat Sigortası Ne Kadar Tutar?
            </h2>
            <div className="flex flex-col sm:flex-row gap-8 items-center">
              <div className="h-full max-h-max p-6 w-full sm:w-[60%] mx-auto bg-yellow rounded-xl shadow-md space-y-4">
                <h2 className="text-2xl font-semibold text-center text-darkgreen">
                  Sağlık Sorularını Mutlaka Doğru Yanıtlayın
                </h2>
                <p className="text-darkgreen">
                  Sigortacılar, belirli önceden var olan hastalıklar için sıkça
                  risk primi talep etse de, sağlığınıza dair soruları kesinlikle
                  doğru yanıtlamanız gerekir. Aksi takdirde, sigorta korumanızı
                  riske atarsınız!
                </p>
              </div>

              <div className="h-full max-h-max p-6 w-full sm:w-[40%] mx-auto bg-purple rounded-xl shadow-md space-y-4 relative">
                <img
                  src={helpIcon}
                  className="absolute w-16 h-16 -top-4 -left-4"
                ></img>
                <h2 className="text-2xl font-semibold text-center text-white">
                  Prim Miktarını Etkileyen Faktörler
                </h2>
                <p className="text-gray-700">
                  Prim miktarı, şu faktörlere bağlı olarak değişiklik gösterir:
                </p>
                <ul className="list-disc list-inside text-white">
                  {[
                    "Sözleşme Süresi",
                    "Sigorta Tutarının Yüksekliği",
                    "Sözleşme Başlangıcındaki Yaş",
                    "Meslek",
                    "Sağlık Durumu",
                    "Sigara İçme Durumu",
                    "Riskli Hobiler",
                  ].map((item, index) => (
                    <li key={index}>{item}</li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </section>
        {lightbox.open && (
          <div
            className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center p-4"
            style={{ zIndex: "1000000000000000000000000000000000" }}
            onClick={closeLightbox}
          >
            <div className="rounded-md bg-white p-2">
              <img
                className="h-full max-h-[600px] max-w-full rounded-lg object-contain"
                src={lightbox.imgSrc}
                alt="Enlarged gallery photo"
                onClick={(e) => e.stopPropagation()} // Prevent click inside the lightbox from closing it
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default RiskHayatSigortasi;
