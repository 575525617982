import React, { useEffect, useState, useRef } from "react";

import ACV_Logo from "../../Assets/ACV_Logo_Claim_Unten_RGB_orange_anthrazit.png";
import DEVKlogo from "../../Assets/DEVKlogo.png";
import whatappLogo from "../../Assets/whatsapp.png";
import ilkAracImg1 from "../../Assets/ilk-arac-img1.png";
import ilkAracImg2 from "../../Assets/ilk-arac-img2.png";
import ilkAracImg3 from "../../Assets/ilk-arac-img3.png";
import helpIcon from "../../Assets/help-icon.png";

import icon1 from "../../Assets/icon1.png";
import icon2 from "../../Assets/icon2.png";
import icon3 from "../../Assets/icon3.png";
import icon4 from "../../Assets/icon4.png";
import Button from "../Button/Button";

const faqsData = [
  {
    question: "Geçerli Hangi Ehliyetler Var?",
    answer:
      "Avrupa Ekonomik Alanı (AEA) üyesi bir ülkeden alınmış veya buna denk kabul edilen geçerli bir sürücü belgeniz varsa, biz de bu ehliyeti dikkate alıyoruz. Ehliyetinizin verildiği tarihten itibaren, başvurduğunuz araç sınıfı için gerekli olan SF-Sınıfı 2'de sizleri değerlendiriyoruz.",
  },
  {
    question: "Teklif İçin Hangi Belgelere İhtiyacım Var?",
    answer:
      "Teklif almak için araç ruhsatınız, varsa ehliyetinizin (ön/arka yüzü), doğum tarihiniz ve adres bilgilerinizi hazır bulundurmanız gerekmektedir.",
  },
  {
    question: "Neden Teklif İçin 4 Ek Sigorta Yapmalıyım",
    answer:
      "Eğer Türk ehliyetinizi Alman ehliyetine çevirirseniz, Alman ehliyeti çevirme tarihi ile güncel tarih alır ve bu nedenle çoğu sigortacı, Hasarsızlık Sınıfı (SF) 0 olarak hesaplama yapar. DEVK, bu durumda dahi sizin daha avantajlı bir konumda olmanızı sağlamak için dört ek sigorta yapmanızı önerir. Bu strateji, otomatik olarak en düşük hasarsızlık sınıfına yerleştirilmenizin önüne geçerek, sigorta kapsamınızı genişletir ve potansiyel olarak ödeyeceğiniz toplam primi azaltır.",
  },
];

export const FAQs = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    if (openIndex === index) {
      setOpenIndex(null); // If clicked again, close it
    } else {
      setOpenIndex(index); // Else, open the clicked one
    }
  };

  return (
    <div className="max-w-4xl mx-auto">
      {faqsData.map((faq, index) => (
        <div key={index} className="">
          <button
            onClick={() => toggleFAQ(index)}
            className="flex justify-between items-center w-full py-6 text-left border-b border-gray-300"
          >
            <span
              className={openIndex === index ? "text-black" : "text-gray-700"}
            >
              {faq.question}
            </span>
            <span>
              {openIndex === index ? (
                <i class="fa-solid fa-chevron-up"></i>
              ) : (
                <i class="fa-solid fa-chevron-down"></i>
              )}
            </span>
          </button>
          {openIndex === index && (
            <div className="py-6 border-b border-gray-300 text-gray-500">
              {faq.answer}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

const features = [
  {
    icon: icon1,
    title: "Müşteri Hizmetimizin Farkı",
    description:
      "Sözleşme sonrası da dahil olmak üzere, her zaman yanınızdayız. Sözleşme sürecinde ve sonrasında her türlü soru ve sorununuzla ilgileniyor, hızlı ve etkili çözümler üretiyoruz. Müşterilerimize, sizlere özel danışmanlık hizmeti sunarak, tüm sigorta ihtiyaçlarınızda yanınızda olmayı taahhüt ederiz. İhtiyaçlarınıza en uygun sigorta paketini birlikte seçiyoruz. Şeffaf ve adil bir hizmet anlayışı ile, sizlere her zaman en iyi ve en uygun sigorta tekliflerini sunmayı hedefliyoruz.",
  },
  {
    icon: icon2,
    title: "Müşterilerimizin Güveni Bizim İçin Değerlidir",
    description:
      "Müşterilerimizin bize olan güveni, işimizin temel taşıdır. Bu güveni sağlamak ve sürdürmek için, müşteri memnuniyetini en üst seviyede tutmayı amaçlıyoruz. Sigortanızı bizimle yaparak, güvende olduğunuzu bilmenizi isteriz. Çünkü biz, müşterilerimizin ihtiyaçlarına değer veren ve onları her durumda destekleyen bir ekibiz.",
  },
  {
    icon: icon3,
    title: "Neden Bizimle Sigorta Yaptırmalısınız?",
    description:
      "Müşteri memnuniyeti, hizmetlerimizin merkezinde yer alır. Biz, sadece bir sigorta şirketi değil, müşterilerimizin güvenilir bir partneriyiz. Siz değerli müşterilerimizin ihtiyaçlarına odaklanarak, sizlere özel sigorta çözümleri sunuyoruz. Sigortanızı bizimle yaptırarak, sadece kapsamlı bir koruma değil, aynı zamanda olağanüstü bir müşteri hizmeti deneyimi de elde edersiniz.",
  },
  {
    icon: icon4,
    title: "Bizimle Sigorta Yaptırın, Huzur Bulun",
    description:
      "Siz de huzur içinde, finansal güvence altında bir yaşam sürmek istiyorsanız, doğru yerdesiniz. İhtiyaçlarınıza en uygun sigorta çözümleri için bizimle iletişime geçin ve farkımızı kendiniz görün.",
  },
];
const FeatureCard = ({ icon, title, description }) => {
  return (
    <div className="py-4 sm:px-4 sm:w-1/2 xl:max-w-xl">
      <div className="flex rounded-md h-full bg-lightgreen p-8 flex-col border-2 border-darkgreen">
        <div className="flex items-center mb-3 gap-4">
          <img
            src={icon}
            className="w-16 h-16 inline-flex items-center justify-center rounded-full flex-shrink-0"
          ></img>

          <h2 className="text-white dark:text-white text-lg font-bold">
            {title}
          </h2>
        </div>
        <div className="flex flex-col justify-between flex-grow">
          <p className="leading-relaxed text-sm text-darkgreen">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
};

function IlkArac() {
  const [lightbox, setLightbox] = useState({ open: false, imgSrc: "" });
  const openLightbox = (imgSrc) => {
    setLightbox({ open: true, imgSrc });
  };

  const closeLightbox = () => {
    setLightbox({ open: false, imgSrc: "" });
  };

  return (
    <>
      <div class="bg-white">
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <div className="space-y-2">
                  <h2 className="text-2xl font-bold text-darkgreen">
                    Almanya'da İlk Kfz Sigortası için Özel Teklif
                  </h2>
                  <p>
                    Almanya'da ilk defa bir arac sigortası yaptırmak
                    istiyorsanız, sizin için mükemmel bir teklifimiz var.
                    Yandaki listelenen gibi Alman ehliyetine sahipseniz, duruma
                    bağlı olarak SF-Sınıfı 2'den SF-Sınıfı 7'ye kadar bir
                    indirim sınıfı alabilirsiniz.
                  </p>
                </div>
                <div className="mt-8 space-y-2">
                  <h2 className="text-xl font-semibold text-mediumorange">
                    Normalde SF-Klasse 0'dan başlarsınız
                  </h2>
                  <p>Şartlar:</p>
                  <p>
                    Siz ve/veya eşiniz toplamda 4 adet sigorta (Kfz ve yurt dışı
                    seyahat sağlık sigortası hariç) DEVK'da yaptırmış ya da
                    başvurmuş olmalısınız.
                  </p>
                </div>
              </div>
              <div class="aspect-w-16 aspect-h-9 mx-auto">
                <img
                  src={ilkAracImg1}
                  alt=""
                  className="w-full h-full max-h-[400px] cursor-pointer"
                  onClick={() => openLightbox(ilkAracImg1)}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <Button />
              <div>
                <div className="flex flex-col space-y-4">
                  <img
                    src={helpIcon}
                    className="self-end mr-8 w-8 h-8"
                    style={{ transform: "rotate(15deg)" }}
                  ></img>
                  <p>
                    WhatsApp üzerinden bizimle iletişime geçtiğinizde, sizin
                    için derhal uygun fiyatlı ve özel bir teklif oluşturacağız!
                  </p>
                  <p>
                    Direkt bize yazabilir ya da arayabilirsiniz; profesyonel
                    ekibimiz, ihtiyaçlarınıza en uygun şekilde danışmanlık
                    yaparak, sizin için ideal Kfz sigortası paketini
                    hazırlamanızda size destek olmaktan mutluluk duyar.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <div className="space-y-2">
                  <h2 className="text-2xl font-bold text-darkgreen">
                    Örneğin:
                  </h2>
                  <p>
                    Almanya'da ilk Kfz sigortanızı paket olarak yaptırmak
                    istediğinizde örnek bir hesaplama:
                  </p>
                </div>
                <div className="mt-8">
                  <ul className="list-disc pl-5 space-y-2">
                    {[
                      "SF-Sınıfı: 2 ",
                      "Sürücünün Yaşı: 49",
                      "Yıllık Kilometre: 6.000 km",
                      "Teilkasko: 150 SB Teilkasko",
                      "Araç Bilgileri: HSN: 5048 TSN: 086 – Üretim Yılı 2006",
                      "Almanya'da Ehliyet Alma Tarihi: 15.01.2023",
                      "Posta Kodu: 35466 Rabenau",
                    ].map((option) => (
                      <li className="dotted pl-4">{option}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div class="aspect-w-16 aspect-h-9 mx-auto">
                <img
                  src={ilkAracImg2}
                  alt=""
                  className="w-full h-full cursor-pointer"
                  onClick={() => openLightbox(ilkAracImg2)}
                />
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4 py-16">
            <div className="grid md:grid-cols-2 gap-8 items-center">
              <div>
                <div className="space-y-2">
                  <h2 className="text-2xl font-bold text-darkgreen">
                    Eğer Almanya'da ilk Kfz sigortanızı paket dışında yaptırmak
                    isterseniz:
                  </h2>
                </div>
                <div className="mt-8">
                  <ul className="list-disc pl-5 space-y-2">
                    {[
                      "SF-Sınıfı: 2",
                      "Sürücünün Yaşı: 49",
                      "Yıllık Kilometre Performansı: 6.000 km",
                      "Kasko Türü: Teilkasko 150 SB",
                      "Araç Bilgileri: HSN: 5048 TSN: 086 – Üretim Yılı 2006",
                      "Almanya'da Ehliyet Alma Tarihi: 15.01.2023",
                      "Posta Kodu: 35466 Rabenau",
                      "(Durum 01.02.2024)",
                    ].map((option) => (
                      <li className="dotted pl-4">{option}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div class="aspect-w-16 aspect-h-9 mx-auto">
                <img
                  src={ilkAracImg3}
                  alt=""
                  className="w-full h-full cursor-pointer"
                  onClick={() => openLightbox(ilkAracImg3)}
                />
              </div>
            </div>
          </div>
        </section>
        <section className="bg-lightgray">
          <div className="container mx-auto px-4 py-16">
            <h2 className="text-2xl font-bold text-darkgreen text-center pb-8">
              Sorulan Sorular ve Cevapları
            </h2>
            <FAQs />
          </div>
        </section>
        <section>
          <div className="container mx-auto px-4 py-16">
            <div className="flex flex-wrap justify-center">
              {features.map((feature, index) => (
                <FeatureCard
                  key={index}
                  icon={feature.icon}
                  title={feature.title}
                  description={feature.description}
                />
              ))}
            </div>
          </div>
        </section>
        {lightbox.open && (
          <div
            className="fixed inset-0 bg-black bg-opacity-80 flex justify-center items-center p-4"
            style={{ zIndex: "1000000000000000000000000000000000" }}
            onClick={closeLightbox}
          >
            <div className="rounded-md bg-white p-2">
              <img
                className="h-full max-h-[600px] max-w-full rounded-lg object-contain"
                src={lightbox.imgSrc}
                alt="Enlarged gallery photo"
                onClick={(e) => e.stopPropagation()} // Prevent click inside the lightbox from closing it
              />
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default IlkArac;
